export const Constants = {
    default: {
        heroName: "",
        heroCategory: "",
        heroYear: "",
        heroDeliverable: "",
        heroTitle: "Product Designer",
    },
    airtable: {
        heroName: "Airtable",
        heroCategory: "Day Job",
        heroYear: "2020–Now",
        heroDeliverable: "Product Designer",
        heroTitle: "Democratizing Software Creation"
    },
    trojan: {
        heroName: "Trojan.JS",
        heroCategory: "Virus",
        heroYear: "2021",
        heroDeliverable: "Net Art",
        heroTitle: "Recreating a JavaScript Virus Within the Safe Confines of a p5.js Canvas"
    },
    textTheater: {
        heroName: "Text Theater",
        heroCategory: "GPT Fun",
        heroYear: "2023",
        heroDeliverable: "Discord Bot",
        heroTitle: "Simulating Scenes from TV Shows from User Prompts on Discord"
    },
    amogus: {
        heroName: "Submit Scan",
        heroCategory: "D3.js Fun",
        heroYear: "2021",
        heroDeliverable: "Net Art",
        heroTitle: "Determining a Crewmate's Sus Levels by Analyzing Users' Among Us Stats"
    },
    phisher: {
        heroName: "Phisher",
        heroCategory: "Hacking",
        heroYear: "2019",
        heroDeliverable: "Net Art",
        heroTitle: "Raising Awareness About Cybersecurity with Gesamtkunstwerk",
    },
    cluse: {
        heroName: "Cluse",
        heroCategory: "A11y Tool",
        heroYear: "2020",
        heroDeliverable: "Sketch Plugin",
        heroTitle: "Developing Inclusive Design Habits for the Average Sketch User",
    },
    ditto: {
        heroName: "Ditto",
        heroCategory: "Font Tool",
        heroYear: "2019",
        heroDeliverable: "Sketch Plugin",
        heroTitle: "Optimizing a Unique Typographic Workflow for UI Designers at Yext"
    },
    trunks: {
        heroName: "Trunks",
        heroCategory: "D3.js Fun",
        heroYear: "2018",
        heroDeliverable: "Data Visualization",
        heroTitle: "Chopping Down the Family Tree Model of Ancestry Visualization"
    },
    semaphore: {
        heroName: "Semaphore",
        heroCategory: "PoseNet Fun",
        heroYear: "2019",
        heroDeliverable: "Net Art",
        heroTitle: "Gamifying Flag Semaphore with Pose Recognition Models"
    }
}
